import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet} from 'react-router-dom';

import MainTopbarSimple from '@/components/layout/main-topbar-simple';
import LoadingView from '@/components/static/loading-view';
import {FirestoreTransportService} from '@/services/api-transport/firestore-transport-service';
import {MockTransportService} from '@/services/api-transport/mock-transport-service';
import {Auth0Service} from '@/services/auth0-service';
import {BackendApiService} from '@/services/backend-api-service';
import {useDebugFlags} from '@/services/debug-service';
import '@/style';

// Display Gabi Environment and define Transport Service
console.log(`Gabi environment: "${__GABI_ENV__}"`);

if (__GABI_ENV__ === 'testing')  {
    BackendApiService.setTransportService(new MockTransportService());
}
else {
    BackendApiService.setTransportService(new FirestoreTransportService());
}

// Root component, including common components surrounding active route
export default function RootPrivacy() {
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(true);
    const debugFlags = useDebugFlags();
    
    useEffect(() => {
        (async () => {
            if (debugFlags.privacyDemoPatient) {
                Auth0Service.setAccessTokenProvider(async () => {
                    return 'demo';
                });
            }
            else {
                const urlParams = document.location.search.substring(1);
                if (urlParams.split('=')[0] === 'token') {
                    const token = urlParams.split('=')[1];
                    Auth0Service.setAccessTokenProvider(async () => {
                        return token;
                    });
                }
            }
            setLoading(false);
        })();
    }, [debugFlags]);

    if (loading) {
        return <LoadingView/>;
    }
    else {
        return (
            <div>
                <MainTopbarSimple title={t('privacy.globalTitle')} />

                <main className="main-content">
                    <Outlet/>
                </main>
            </div>
        );
    }
}
